import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);
export const createOrder = async (orderData) => {
  try {
    const response = await axiosInstance.post('/orders', orderData);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const getOrder = async (orderId) => {
  try {
    const response = await axiosInstance.get(`/orders/${orderId}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting order:', error);
    throw error;
  }
};
export const getOrderAsAdmin = async (orderId) => {
  try {
    const response = await axiosInstance.get(`/orders/${orderId}/admin`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting order:', error);
    throw error;
  }
};

export const updateOrder = async (orderId, orderData) => {
  try {
    const response = await axiosInstance.put(`/orders/${orderId}`, orderData);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
};
export const updateOrderAsAdmin = async (orderId, orderData) => {
  try {
    console.log('Updating order:', orderId, orderData);

    const response = await axiosInstance.put(
      `/orders/${orderId}/admin`,
      orderData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
};

export const getOrders = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/orders?${queryString}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting orders:', error);
    throw error;
  }
};
export const getOrdersAsAdmin = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/orders/admin?${queryString}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting orders:', error);
    throw error;
  }
};

export const createOrderOutsourcing = async (orderData) => {
  try {
    const response = await axiosInstance.post(
      '/orders/OrderOutsourcing',
      orderData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};
