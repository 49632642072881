import { useEffect, useState, useCallback } from 'react';
import {
  getOrder,
  createOrder as createOrderService,
  updateOrder as updateOrderService,
  getOrdersAsAdmin,
  createOrderOutsourcing,
} from '@/services/order';

// Hook to fetch a single order by ID
export const useFetchOrder = (orderId) => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchOrder = useCallback(async () => {
    if (!orderId) return;

    setLoading(true);
    setError(null);
    try {
      const { order: fetchedOrder } = await getOrder(orderId);
      if (fetchedOrder) {
        setOrder(fetchedOrder);
      } else {
        setError('No order found');
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          error.message ||
          'Error fetching order',
      );
      console.error('Error fetching order:', error);
    } finally {
      setLoading(false);
    }
  }, [orderId]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]); // fetchOrder is already memoized, so no need to add additional dependencies

  return { order, loading, error, fetchOrder }; // Return refetch to allow manual reload of the order
};

// Hook to create a new order
export const useCreateOrder = () => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createOrder = useCallback(async (data) => {
    setLoading(true);
    setError(null);
    try {
      const { order: newOrder } = await createOrderService(data);
      if (newOrder) {
        setOrder(newOrder);
      } else {
        setError('Error creating order');
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          error.message ||
          'Error creating order',
      );
      console.error('Error creating order:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  return { order, loading, error, createOrder };
};

export const useCreateOrderOutsourcing = () => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createOrder = useCallback(async (data) => {
    setLoading(true);
    setError(null);
    try {
      const { order: newOrder } = await createOrderOutsourcing(data);
      if (newOrder) {
        setOrder(newOrder);
      } else {
        setError('Error creating order');
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          error.message ||
          'Error creating order',
      );
      console.error('Error creating order:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  return { order, loading, error, createOrder };
};

// Hook to update an existing order
export const useUpdateOrder = () => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateOrder = useCallback(async (orderId, data) => {
    setLoading(true);
    setError(null); // Reset error state before an update
    try {
      const { order: updatedOrder } = await updateOrderService(orderId, data);
      if (updatedOrder) {
        setOrder(updatedOrder);
      } else {
        setError('Error updating order');
      }
    } catch (error) {
      setError(error.message || 'Error updating order');
      console.error('Error updating order:', error);
    } finally {
      setLoading(false);
    }
  }, []); // No dependencies here as the order ID and data are passed during function call

  return { order, loading, error, updateOrder };
};

// Hook to fetch multiple orders (with search params)
export const useFetchOrders = (searchParams) => {
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const params = searchParams ? Object.fromEntries(searchParams) : {};
      const queryString = new URLSearchParams(params).toString();
      const { orders, total } = await getOrdersAsAdmin({ queryString });
      setOrders(orders);
      setTotal(total);
    } catch (error) {
      setError(
        error.response?.data?.message ||
          error.message ||
          'Error fetching orders',
      );
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]); // fetchOrders is already memoized, so no need to add additional dependencies

  return { orders, total, loading, error, refetch: fetchOrders }; // Return refetch to allow manual reload of the orders
};
